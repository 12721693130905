import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";
import BookingGetters from "../../../store/onlineBooking/getters";
import React, { useEffect, useState } from "react";
import theme from "../../../config/MuiColorThemes";
import { Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "../../../config/i18n";

function VehicleExtraOptions({
  iconUrl,
  optionName,
  pricePerDayInUSD,
  details,
  onDecrementClick,
  onIncrementClick,
  value,
  cardBackgroundColor,
  selectedCardBackgroundColor,
  cardTextColor,
  selectedCardTextColor,
  secondaryColor,
  fillToCard,
  showOptionalCode,
  showOptionalDescription,
  showDescFromBookingText,
}): JSX.Element {
  const buttonBorderStyles =
    "border-solid border-black-300 border-2 rounded-lg ";
  const appSettings = BookingGetters.getAppSettings();
  const lang = i18n.language;
  const { t: translate } = useTranslation();
  const textResource = BookingGetters.getTextResourceDetailsBasedOnLang();
  const data = textResource?.[lang]?.flatMap((item) => item.data || []) || [];
  const [optionalBody, setOptionalBody] = useState<string>("");

  useEffect(() => {
    const optionalObject = data.find((item) => item.key === optionName);
    const optionalData = optionalObject ? optionalObject?.body : "";
    setOptionalBody(optionalData);
  }, [lang, textResource, translate]);

  const defaultCurrency = appSettings?.defaultCurrency;
  return (
    <Card
      className={`shadow-md shadow-sm md-4`}
      sx={{
        maxWidth: 550,
        borderRadius: theme.shape.borderRadius,
        boxShadow:
          "0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 16px rgba(0, 0, 0, 0.1)",
        margin: 1,
      }}
      style={
        fillToCard
          ? {
              backgroundColor:
                value > 0 ? selectedCardBackgroundColor : cardBackgroundColor,
              color: value > 0 ? selectedCardTextColor : cardTextColor,
            }
          : {}
      }
    >
      <div className="flex flex-row w-full h-full relative " id="">
        {iconUrl != "#" && appSettings?.showHideExtrasImages && (
          <aside
            className="flex flex-row items-center w-[25%] md:w-[18%]"
            style={{ marginBottom: "60px" }}
          >
            <img src={iconUrl} className="aspect-square"></img>
          </aside>
        )}
        <main className="flex flex-col box-border px-4 grow max-w-[75%] grow text-sm relative mt-2 mb-2">
          {showOptionalCode && (
            <span
              className="font-bold break-words "
              style={
                fillToCard
                  ? {
                      color: value > 0 ? selectedCardTextColor : cardTextColor,
                    }
                  : {}
              }
            >
              {optionName}
            </span>
          )}
          {showOptionalDescription && (
            <span
              className="font-bold break-words "
              style={
                fillToCard
                  ? {
                      color: value > 0 ? selectedCardTextColor : cardTextColor,
                    }
                  : {}
              }
            >
              {details}
            </span>
          )}
          {showDescFromBookingText && (
            <span
              className="font-bold break-words grow overflow-hidden hover:overflow-auto"
              style={
                fillToCard
                  ? {
                      color: value > 0 ? selectedCardTextColor : cardTextColor,
                    }
                  : {}
              }
            >
              {optionalBody ? optionalBody : optionName}
            </span>
          )}
          {!showOptionalCode &&
            !showOptionalDescription &&
            !showDescFromBookingText && (
              <span
                className="font-bold break-words grow overflow-hidden hover:overflow-auto"
                style={
                  fillToCard
                    ? {
                        color:
                          value > 0 ? selectedCardTextColor : cardTextColor,
                      }
                    : {}
                }
              >
                {optionName}
              </span>
            )}
          <span
            className="bottom-0 relative"
            style={{ color: secondaryColor, fontWeight: "bold" }}
          >
            {defaultCurrency ||
              formatPriceWithCurrencySymbol(BookingGetters.getCurrency())
                .symbol}
            {"  "}
            {pricePerDayInUSD}
          </span>
        </main>
        <aside
          className={`flex flex-col gap-2 h-full ${
            iconUrl ? "mr-[20px]" : "mr-0"
          }`}
        >
          <div
            onClick={() => {
              onIncrementClick();
            }}
            className={
              "w-6 h-6 flex items-center text-center justify-center  cursor-pointer select-none mt-2" +
              " " +
              buttonBorderStyles
            }
            style={{
              backgroundColor: value > 0 ? selectedCardBackgroundColor : "",
              color: value > 0 ? selectedCardTextColor : "",
            }}
          >
            <span className="w-fit h-fit  black  ">+</span>
          </div>
          <span className="grow flex items-center justify-center black">
            {value}
          </span>
          <div
            onClick={() => {
              onDecrementClick();
            }}
            className={
              "w-6 h-6 flex items-center text-center justify-center cursor-pointer select-none text-black-600 mb-2" +
              " " +
              buttonBorderStyles
            }
          >
            -
          </div>
        </aside>
      </div>
    </Card>
  );
}
export { VehicleExtraOptions };
export { VehicleExtraOptions as default };
