import { BookingDetails } from "../../@types/Booking/bookingDetails";
import {
  CustomerPrimaryDetails,
  DefaultValuesDetailsForm,
} from "../../components/Layouts/WebCheckInForms/WebCheckInForms.props";
import { DetailsFormValues } from "./../../components/Layouts/WebCheckInForms/WebCheckInForms.props";
import { StateSelectedVehicleDetails } from "./../../@types/states/StateSelectedVehicleDetails.d";
import i18n from "../../config/i18n";
import {
  AppSettingResponse,
  BookingTextResource,
  BookingTextresource,
  BookingTextresourceLang,
  NewPayment,
} from "../../@types/DTO/AppSetting";
import { Location } from "../../@types/DTO/Locations";

export interface BookingStateRedux {
  isActive: boolean;
  isLoggedIn: boolean;
  isEmbedded: boolean;
  bookingInformation: BookingDetails;
  currency: string;
  rentalTerms: string;
  selectedLanguage: string;
  sessionExpiryTime: number;
  selectedVehicleDetails: StateSelectedVehicleDetails;
  userDetails: DetailsFormValues;
  appSetting?: AppSettingResponse;
  textresource?: BookingTextresource;
  customerPrimaryDetails: CustomerPrimaryDetails;
  affiliateLocationDetails: Record<string, Location[]>;
  textResourceBasedOnLang?: Record<string, BookingTextResource[]>;
}
const states: BookingStateRedux = {
  isActive: true,
  isLoggedIn: false,
  isEmbedded: false,
  bookingInformation: {
    dropOffDateAndTime: null,
    dropOffLocationDetails: {
      name: "",
      code: "",
    },
    pickUpDateAndTime: null,
    pickUpLocationDetails: {
      name: "",
      code: "",
    },
    reservationSource: {
      name: "",
      code: "",
    },
    agreementCoupon: "",
    discountValueWithoutVat: "",
    macroGroupName: "",
    showPics: true,
    showOptionalImage: true,
    showVehicleParameter: true,
    showVehicleExtraImage: true,
    showBookingDiscount: true,
    isYoungDriverAge: "false",
    isSeniorDriverAge: "false",
    noFeeAge: "false",
    isAgeLimitSelected: "false",
  },
  currency: "EUR",
  rentalTerms: `
 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent volutpat convallis ex 
 et pellentesque. Duis egestas interdum sem sed scelerisque. Ut sed sodales lacus. Nam sollicitudin 
 auctor pellentesque. Nunc ligula elit, ultricies in sem ut, scelerisque congue est. Fusce malesuada, 
 tortor quis suscipit aliquet, risus arcu finibus lacus, id condimentum ante urna id risus. Duis quis ante 
 molestie, varius metus sit amet, laoreet massa. Nullam sed ligula iaculis, scelerisque urna a, malesuada ligula.
  Pellentesque rutrum, quam vel scelerisque dapibus, magna magna suscipit odio, sed viverra dolor odio non nulla. 
  Fusce feugiat nisi eu quam posuere condimentum eget id justo. Maecenas quis erat quis ex faucibus lobortis vitae in mauris. 
  Etiam aliquam ante id eros mollis vehicula. Donec lacinia lorem ut hendrerit euismod.

In a sagittis est. Maecenas euismod venenatis diam, at volutpat libero mattis a. Quisque lacus lectus, tempor pellentesque 
tristique non, eleifend vel neque. Praesent id venenatis neque. Pellentesque habitant morbi tristique senectus et netus et malesuada 
fames ac turpis egestas. Nam vel justo risus. Integer rutrum urna sed semper dictum. Fusce hendrerit dolor ac leo scelerisque dictum.
 Suspendisse potenti. Phasellus velit mauris, efficitur vel justo eu, porta lacinia lacus. Cras non nisl elementum, dictum diam eget, 
 sodales nibh. Cras fringilla turpis eu metus congue dapibus. Nunc accumsan augue tempus metus maximus, 
 at aliquet velit accumsan. Nullam accumsan orci dolor, et pulvinar magna luctus efficitur. 
  `,
  selectedLanguage: i18n.language,
  sessionExpiryTime: 0,
  selectedVehicleDetails: {
    paymentDetails: {
      type: "PAYNOW",
      addedExtraOptions: {},
      price: 0,
      isDiscounted: 0,
      selectedInsurance: {
        price: 0,
        details: "",
        name: "insurance",
        insuranceId: "-1",
      },
    },
    vehicleDetails: undefined,
  },
  userDetails: { ...DefaultValuesDetailsForm, isDriver: "NO" },
  appSetting: undefined,
  textresource: undefined,
};
export { states as default };
