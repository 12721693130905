import {
  AccountCircleOutlined,
  AddBox,
  CalendarMonthOutlined,
  LoginOutlined,
  LogoutOutlined,
} from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LoginIcon from "@mui/icons-material/Login";
import React, { useEffect, useState } from "react";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LogoutIcon from "@mui/icons-material/Logout";
import CarRentalIcon from "@mui/icons-material/CarRental";
import { AppFlagSelect } from "../../UI/AppSelectOptions/AppSelect";
import { AppStandardButton } from "../../UI/AppButtons";
import BookingGetters from "../../../store/onlineBooking/getters";
import { HeaderConfiguration } from "../../../@types/Pages/PageConfiguration";
import { LoggedInUserMenu } from "../../UI/AppMenu";
import { PageConfigGetters } from "../../../store/PageConfigurations/getters";
import PropTypes from "prop-types";
import { TimeInMinutes } from "../../../helpers/timerConfig";
import { differenceInSeconds } from "date-fns";
import { useTimer } from "react-timer-hook/dist/index";
import { useTranslation } from "react-i18next";
import AppButton from "../../UI/AppButtons/AppButton";
import MenuForMobile from "./HamBurger";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import theme, { formatTheme } from "../../../config/MuiColorThemes";
import { t } from "i18next";
import { BookingApi } from "../../../API/Apis/BookingApi";
import {
  BookingTextResource,
  TextResource,
} from "../../../@types/DTO/AppSetting";
import { BOOKING_ACTIONS } from "../../../store/onlineBooking";
import { useDispatch } from "react-redux";

const MenuOptions = [
  {
    label: "Nuova prenotazione",
    iconUrl: <AddBox />,
    clickHandler: () => {},
    href: "/",
  },
  {
    label: "I miei dati personali",
    iconUrl: <AccountCircleOutlined />,
    clickHandler: () => {},
    href: "/accounts",
  },
  {
    label: "Le mie prenotazioni",
    iconUrl: <CalendarMonthOutlined />,
    clickHandler: () => {},
    href: "/my-reservations",
  },
  {
    label: "Logout",
    iconUrl: <LogoutOutlined />,
    clickHandler: () => {},
  },
];
const MenuHeader = {
  label: "Admin",
  avatar: (
    <img
      alt="user profile picture"
      src="https://ui-avatars.com/api/?name=Admin&color=7F9CF5&background=EBF4FF"
      className="h-12 w-12 m-0 p-0 border-2 border-solid border-grey-800 rounded-full box-border"
    />
  ),
  avatarClickHandler: () => {},
  labelClickHandler: () => {},
};

AppHeader.propTypes = {
  className: PropTypes.string,
};
AppHeader.defaultProps = {
  className: "",
};

export default function AppHeader({ dynamicTheme, className }): JSX.Element {
  const { i18n } = useTranslation();
  const navigateTo = useNavigate();
  PageConfigGetters.getAppHeaderConfig();
  const expiryTime = BookingGetters.getSessionExpiryTime();
  const isLogged = localStorage.getItem("isUserLoggedin");
  const location = useLocation();
  const { minutes, seconds, pause } = useTimer({
    expiryTimestamp: new Date(expiryTime || Date.now() + 99999999999),
    onExpire() {},
  });

  useEffect(() => {
    const d = Math.ceil(
      differenceInSeconds(new Date(expiryTime), new Date().getTime()) / 60
    );
    if (location.pathname != "/") {
      if (d == 0) {
        alert("expired");
        navigateTo("/");
      }
    }
  });

  function onLogout() {
    if (isLogged == "true") {
      console.log("user is logged out ");
      localStorage.setItem("isUserLoggedin", "false");
      localStorage.removeItem("userID");
      localStorage.removeItem("customerId");
      navigateTo("/signin");
      handleClose();
    } else {
      alert("You are already logged out");
    }
  }
  const appSettings = BookingGetters.getAppSettings();
  const [showWebCheckIn, setShowWebCheckIn] = useState<boolean>(
    !!appSettings?.webCheckIn
  );
  useEffect(() => {
    setShowWebCheckIn(!!appSettings?.webCheckIn);
  }, [appSettings]);
  const [popperAnchorEl, setPopperAnchorEl] = useState<Element | null>(null);
  const [showMenu, setShowMenu] = useState(true);
  const [selected, changeSelected] = useState(-1);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);
  function handlePopperClick() {}

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setShowMenu(false);
    setPopperAnchorEl(null);
  };
  const [smallScreen] = useState(window.innerWidth < 768);
  const openProfile = () => {
    navigateTo("/profile");
    handleClose();
  };

  // const openBooking = () => {
  //   navigateTo("/my-reservations");
  //   handleClose();
  // };
  const dispatch = useDispatch();
  const customerOpen = () => {
    navigateTo("/customerDetailsList");
    handleClose();
  };
  const openNewBooking = () => {
    navigateTo("/");
    handleClose();
  };
  const onLogin = () => {
    navigateTo("/signin");
  };
  const [queryParams] = useSearchParams();
  const isUserLoggedin = localStorage.getItem("isUserLoggedin");
  const userType = localStorage.getItem("onlineUserType");
  const [showReserve] = useState<boolean>(!!appSettings?.reservedArea);
  const [logo, setLogo] = useState("");
  const logoUrl = appSettings && appSettings["config"];
  useEffect(() => {
    if (logoUrl != null) {
      setLogo(logoUrl.logoUrl);
    }
  });
  useEffect(() => {
    const currentLanguage = queryParams.get("lang");
    if (currentLanguage && i18n.languages.includes(currentLanguage)) {
      i18n.changeLanguage(currentLanguage);
      setCurrentLanguage(currentLanguage);
    }
  }, [queryParams]);
  useEffect(() => {
    console.log("hit");
    console.log("currentLanguage", currentLanguage);
    const fetchTextResources = async () => {
      try {
        const response = await BookingApi.getTextResource(currentLanguage);
        const textResource: TextResource[] = response.data?.data || [];
        const bookingTextResource: BookingTextResource = {
          data: textResource,
        };
        dispatch(
          BOOKING_ACTIONS.setTextResourceDetailsBasedOnLang({
            [currentLanguage]: [bookingTextResource],
          })
        );
      } catch (error) {
        console.error("Error fetching text resources:", error);
      }
    };
    fetchTextResources();
  }, [currentLanguage]);
  const homePageConfig = BookingGetters.getAppSettings();
  const headerAndFooter = homePageConfig && homePageConfig["headerAndFooter"];
  return (
    <>
      {headerAndFooter && (
        <ThemeProvider theme={dynamicTheme}>
          <header className={"flex flex-col "}>
            <div className="container mx-auto">
              {/* <nav
            className={`h-24 box-border py-4 flex flex-row ${className || ""}`}
          >*/}
              {/*<div className="md:w-1/4 w-3/5 h-full box-border md:flex-shrink-0">
              <Link to="/" style={{ outline: "unset" }}>
                {logo && (
                  <img
                    src={logo}
                    alt="App Logo Here"
                    className="w-full h-full box-border px-8 object-contain"
                  />
                )}
              </Link>
            </div>*/}
              <aside className="md:flex flex-row justify-end items-center border-box p-2 gap-1 font-bold md:w-full float-right">
                {/*  {expiryTime > 0 && (
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                  <CircularProgress
                    size={50}
                    variant="determinate"
                    color="primary"
                    value={
                      (100 *
                        differenceInSeconds(
                          new Date(expiryTime),
                          new Date().getTime()
                        )) /
                      (TimeInMinutes * 60)
                    }
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      color="text.secondary"
                      fontSize={"0.8rem"}
                    >
                      {Math.ceil(
                        differenceInSeconds(
                          new Date(expiryTime),
                          new Date().getTime()
                        ) / 60
                      )}{" "}
                      min
                    </Typography>
                  </Box>
                </Box>
              )}*/}
                {/* <div className="flex flex-wrap items-center hidden sm:block">
                {showWebCheckIn ? (
                  <div>
                    <Link to={"/booking/web-check-in"}>
                      <AppStandardButton
                        variant={"contained"}
                        title={"web check in"}
                        color={"primary"}
                        disableElevation
                      >
                        <p className="flex gap-2 flex-wrap">
                          <LoginOutlined />
                          <span> Web Check-in</span>
                        </p>
                      </AppStandardButton>
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>*/}
                {/* <div
                className={`flex flex-wrap items-center ${
                  smallScreen ? "ml-[-20px]" : ""
                }`}
              >*/}
                <AppFlagSelect
                  onChange={(e: string) => {
                    if (e.toUpperCase().includes("US")) {
                      e = "en";
                    }
                    i18n.changeLanguage(e).then(() => {
                      setCurrentLanguage(e);
                      const searchParams = new URLSearchParams(
                        window.location.search
                      );
                      searchParams.set("lang", e);
                      const newUrl = `${window.location.protocol}//${
                        window.location.host
                      }${window.location.pathname}?${searchParams.toString()}`;
                      window.history.pushState({ path: newUrl }, "", newUrl);
                    });
                  }}
                  selectStyles={{ borderRadius: "3rem" }}
                  className="h-8 border-box w-16"
                  value={
                    currentLanguage?.includes("en")
                      ? "us"
                      : currentLanguage.toLowerCase()
                  }
                  options={["us", "it"]}
                />
                {/*  <div className="md:hidden">
              {" "}
              <MenuForMobile />
            </div>*/}
                {/*   </div>*/}
                {/* {showReserve ? (
                <div className="hidden sm:block">
                  {isUserLoggedin == "true" ? (
                    <div>
                      <Button
                        title={""}
                        id="demo-positioned-button"
                        aria-controls={
                          open ? "demo-positioned-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <AccountCircleIcon
                          style={{ color: "primary" }}
                          className="px-1 mr-1 bg-white"
                          sx={{ fontSize: "3rem" }}
                        />
                      </Button>
                      <div className="space-x-4 text-black-500">
                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <div className="hover:bg-[primary]">
                            <MenuItem onClick={openNewBooking}>
                              {" "}
                              <DashboardIcon className="px-1  mr-1 " />
                              DashBoard{" "}
                            </MenuItem>
                          </div>
                          <div className="hover:bg-[primary]">
                            <MenuItem onClick={openBooking}>
                              {" "}
                              <CarRentalIcon className="px-1  mr-1 " /> My
                              Booking{" "}
                            </MenuItem>
                          </div>
                          <div className="hover:bg-[primary]">
                            <MenuItem onClick={openProfile}>
                              {" "}
                              <AccountCircleIcon className="px-1 mr-1" /> My
                              Profile
                            </MenuItem>
                          </div>
                          {userType == "EndUser" ? (
                            <div className="hover:bg-[primary]">
                              <MenuItem onClick={customerOpen}>
                                {" "}
                                <GroupAddIcon className="px-1  mr-1" />
                                Driver Details
                              </MenuItem>
                            </div>
                          ) : (
                            <div className="hover:bg-[primary]">
                              <MenuItem
                                className="!hover:bg-[#020D53] text-[#020D53]"
                                onClick={customerOpen}
                              >
                                {" "}
                                <GroupAddIcon className="px-1  mr-1" />
                                Customer Details
                              </MenuItem>
                            </div>
                          )}
                          <div className="hover:bg-[primary]">
                            <MenuItem onClick={onLogout}>
                              <LogoutIcon className="px-1  mr-1" />
                              Logout
                            </MenuItem>
                          </div>
                        </Menu>
                      </div>
                    </div>
                  ) : (
                    <AppButton
                      onClick={onLogin}
                      color="primary"
                      className="h-8 text-black-500"
                    >
                      Login
                    </AppButton>
                  )}
                </div>
              ) : (
                ""
              )}*/}
                {/*
              {BookingGetters.getIsLoggedIn() && MenuOptions && (
                <LoggedInUserMenu
                  selectedIndex={selected}
                  anchorElement={popperAnchorEl}
                  clickHandler={handlePopperClick}
                  isOpen={showMenu}
                  handleClose={handleClose}
                  changeOptionSelected={(e) => {
                    changeSelected(e);
                  }}
                  options={MenuOptions || []}
                  menuHeader={MenuHeader}
                />
              )}*/}
              </aside>
              {/*  </nav>*/}
            </div>
          </header>
        </ThemeProvider>
      )}
    </>
  );
}
