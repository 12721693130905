import { BookingVehicleInfoCard } from "../BookingVehicleInfoCard/BookingVehicleInfoCard";
import React, { useEffect, useMemo, useState } from "react";
import { Vehicle } from "../../../@types/DTO/Vehicles";
import { ShowVehiclesProps } from "./ShowVehiclesProps";
import { Box, CircularProgress } from "@mui/material";
import "./Scrollbar.css";
import BookingGetters from "../../../store/onlineBooking/getters";
import VehicleCardPitView from "../BookingVehicleInfoCard/VehicleCardPitView";
import { useTranslation } from "react-i18next";
import i18n from "../../../config/i18n";

export function ShowVehicles({
  vehiclesFilter = {
    status: {},
    category: {},
    type: {},
    TotalSeats: [],
    MacroGroup: {},
    Transmission: {},
  },
  allVehicles = [],
  className = "",
  textColor = "",
  backgroundColor = "",
  onVehicleSelect = () => {},
  backgroundTextColor = "",
  reservationSourcePayNow,
  reservationSourcePayLater,
  errorMsgNoVehicle,
  isExpanded1,
  isExpanded2,
  isExpanded3,
  isExpanded4,
}: ShowVehiclesProps): JSX.Element {
  const [userId] = useState(localStorage.getItem("userID"));
  const memoizedVehicleFilter = useMemo(() => vehiclesFilter, [vehiclesFilter]);
  const [availableVehicles, setAvailableVehicles] = useState<Vehicle[]>([]);
  const [showingVehicles, setShowingVehicles] = useState<Vehicle[]>([]);
  const [isLoadingMoreVehicles, setIsLoadingMoreVehicles] =
    useState<boolean>(false);
  const appSettings = BookingGetters.getAppSettings();
  const enableFilterBySeat = appSettings && appSettings["enableFilterBySeat"];
  const enableFilterByFuel = appSettings && appSettings["enableFilterByFuel"];
  const enableFilterByTransmission =
    appSettings && appSettings["enableFilterByTransmission"];
  const istabletScreen = window.innerWidth > 768 && window.innerWidth < 1300;
  const isDesktopScreen = window.innerWidth >= 1310;
  const isIpadMiniScreen = window.innerWidth >= 500 && window.innerWidth <= 768;
  const { t: translate } = useTranslation();
  //const textResource = BookingGetters.getTextResource()?.data ?? [];
  const lang = i18n.language;
  const textResource = BookingGetters.getTextResourceDetailsBasedOnLang();
  const data = textResource?.[lang]?.flatMap((item) => item.data || []) || [];
  const [noVehicleLabel, setNoVehicleLabel] = useState<String>("");
  useEffect(() => {
    const noVehicleData = data.find(
      (item) => item.key === "noVehicleAvailableLabel"
    );
    setNoVehicleLabel(
      noVehicleData?.body || translate("No Vehicles Available")
    );
  }, [textResource, lang, translate]);

  function addAvailableVehicles() {
    const result: Vehicle[] = availableVehicles;
    if (result.length) {
      setShowingVehicles((e) => {
        return [...e, ...result];
      });
    }
    return result;
  }
  function loadMoreVehicles() {
    let result: Vehicle[] = [];
    setIsLoadingMoreVehicles(true);
    if (
      vehiclesFilter?.status["available"] &&
      showingVehicles.length < availableVehicles.length
    ) {
      result = [...result, ...addAvailableVehicles()];
    }
    if (!Object.values(vehiclesFilter?.status || {}).length) {
      result = [...result, ...addAvailableVehicles()];
    }
    setIsLoadingMoreVehicles(false);
  }

  const isSmallScreen = window.innerWidth <= 768;
  const [availableVehiclesHeight, setAvailableVehiclesHeight] =
    useState<number>(0);
  const [availableVehiclesHeightMobile, setAvailableVehiclesHeightMobile] =
    useState<number>(0);
  const extraIframeHeightVehiclesMobile =
    Number(appSettings?.iframeHeightSecondPageForMobile) || 40;
  const extraIframeHeightVehiclesDesktop =
    Number(appSettings?.iframeHeightForSecondPage) || 40;
  const [allCardHeightMobile, setAllCardHeightMobile] = useState(0);
  const onCardHeightChangeMobile = (height) => {
    setAllCardHeightMobile((prevHeight) => prevHeight + height);
  };
  useEffect(() => {
    if (!isSmallScreen) {
      if (availableVehicles.length <= 2) {
        setAvailableVehiclesHeight(900);
        return;
      }
      const showFourVehicleInRow = AppSettings?.showThreeVehicleCardInArow;
      const vehicleRows = Math.ceil(
        availableVehicles.length / (showFourVehicleInRow ? 4 : 3)
      );
      const filterAndRecapHeight = 300; //ideally 290px //184px (recap with padding) + 90px (fliterheightAndPadding)
      //394.6px(Each card height when 4 vehcile in row) 364.5(Each card height when 3 vehcile in row) + 16px*number of rows of vehicles
      const vehiclesCardHeight = showFourVehicleInRow ? 394.6 : 364.5;
      let totalHeight;
      totalHeight =
        vehicleRows * (vehiclesCardHeight + 16) + filterAndRecapHeight;
      //discount applied takes 52px
      if (availableVehicles[0]?.Reference?.ID > 0) {
        totalHeight += vehicleRows * 52;
      }
      if (availableVehicles[0]?.addedOnewayOOHFee) {
        totalHeight += vehicleRows * 19;
      }
      if (availableVehicles[0]?.addedYoungDriverFee) {
        totalHeight += vehicleRows * 19.5;
      }
      if (extraIframeHeightVehiclesDesktop && vehicleRows != 0) {
        totalHeight += extraIframeHeightVehiclesDesktop;
      }
      setAvailableVehiclesHeight(totalHeight); //adding 70 to give some extra space below
    } else {
      const vehicleRows = availableVehicles.length;
      const vehiclesCardHeight = 369;
      let totalHeight;
      const recapHeight = isExpanded1
        ? 377
        : isExpanded2
        ? 361
        : isExpanded3
        ? 371
        : isExpanded4
        ? 349
        : 221;
      totalHeight = vehicleRows * (vehiclesCardHeight + 16) + recapHeight + 25; //25px is filter approximate height & 16px is gap betwwen each card
      if (availableVehicles[0]?.Reference?.ID > 0) {
        totalHeight += vehicleRows * 30;
      }
      if (availableVehicles[0]?.addedOnewayOOHFee) {
        totalHeight += vehicleRows * 20;
      }
      if (availableVehicles[0]?.addedYoungDriverFee) {
        totalHeight += vehicleRows * 20;
      }
      if (extraIframeHeightVehiclesMobile && vehicleRows != 0) {
        totalHeight += extraIframeHeightVehiclesMobile;
      }
      if (vehicleRows == 0) {
        setAvailableVehiclesHeightMobile(720 + recapHeight);
      } else {
        setAvailableVehiclesHeightMobile(totalHeight);
      }
    }
  }, [availableVehicles, isSmallScreen, isDesktopScreen, allCardHeightMobile]);
  function requestParentResize() {
    let iframeHeight;
    if (isSmallScreen) {
      const recapHeight = isExpanded1
        ? 355
        : isExpanded2
        ? 366
        : isExpanded3
        ? 376
        : isExpanded4
        ? 354
        : 223;
      if (availableVehicles.length == 0) {
        iframeHeight = 720 + recapHeight;
      } else {
        const allCardHeight =
          allCardHeightMobile +
          (availableVehicles.length + 1) * 16 +
          recapHeight +
          35 +
          extraIframeHeightVehiclesMobile +
          50; //30 is extra height taken
        if (allCardHeight) {
          iframeHeight = allCardHeight;
          console.log("total dynamic card height mobile", iframeHeight);
        } else {
          iframeHeight = availableVehiclesHeightMobile;
          console.log("total static card height mobile", iframeHeight);
        }
      }
    } else {
      iframeHeight =
        availableVehicles.length == 0
          ? isSmallScreen
            ? 840
            : 725
          : availableVehiclesHeight;
      console.log(iframeHeight, "in desktop iframeheight from booking");
    }
    window.parent.postMessage(
      {
        event_id: "iframeHeight",
        data: {
          iframeHeight: iframeHeight,
        },
      },
      "*"
    );
  }

  const AppSettings = BookingGetters.getAppSettings();
  useEffect(() => {
    if (AppSettings?.showInIframe) {
      requestParentResize();
    }
  }, [showingVehicles]);

  function segregateData() {
    setAvailableVehicles(
      (
        allVehicles.filter(
          (e) =>
            e.Status.toLowerCase() === "available" ||
            (appSettings?.showOnrequestVehicles &&
              e.Status.toLowerCase() === "onrequest")
        ) || []
      )
        .filter((vehicle) => {
          const validKeys = Object.keys(vehiclesFilter.type).filter(
            (key) => key.trim() !== "" // Ignore empty or whitespace-only keys
          );
          const length = validKeys.length;
          if (length < 1) {
            return true;
          }
          const fuelType =
            vehicle.Vehicle?.fuelType?.description?.toLowerCase() || "";
          return fuelType in vehiclesFilter.type;
        })
        .filter((e) => {
          if (!vehiclesFilter.TotalSeats.length) {
            return true;
          }
          return vehiclesFilter.TotalSeats.filter(
            (v) => v == (e.Vehicle?.seats.toString() || "0")
          ).length;
        })
        .filter((e) => {
          if (
            !Object.keys(vehiclesFilter.Transmission).filter(
              (key) => key.trim() !== "" // Filter out keys that are empty or whitespace
            ).length
          ) {
            return true;
          }
          const selectedTransmission = vehiclesFilter.Transmission;
          const transmissionDescription =
            e.Vehicle?.transmission?.description?.toLowerCase() || "";
          return Object.keys(selectedTransmission)
            .map((e) => e.toLowerCase())
            .includes(transmissionDescription.toLocaleLowerCase());
        })
        .sort((a, b) => {
          const priceA = a.TotalCharge?.RateTotalAmount || 0;
          const priceB = b.TotalCharge?.RateTotalAmount || 0;

          if (priceA === 0) return 1;
          if (priceB === 0) return -1;

          if (appSettings?.showOnrequestVehicles) {
            if (
              a.Status.toLowerCase() === "onrequest" &&
              b.Status.toLowerCase() !== "onrequest"
            ) {
              return 1;
            } else if (
              a.Status.toLowerCase() !== "onrequest" &&
              b.Status.toLowerCase() === "onrequest"
            ) {
              return -1;
            }
          }

          return priceA - priceB;
        })
    );
  }

  useEffect(() => {
    segregateData();
    RefreshData("allVehicles");
  }, [allVehicles]);

  useEffect(() => {
    segregateData();
    RefreshData("allVehicles");
  }, [vehiclesFilter]);

  function RefreshData(reason: string) {
    setShowingVehicles([]);
    loadMoreVehicles();
  }

  useEffect(() => {
    RefreshData("vehiclesFilter");
  }, [availableVehicles, memoizedVehicleFilter]);

  useEffect(() => {
    RefreshData("init");
  }, []);
  const enableAnyFilter =
    enableFilterBySeat || enableFilterByFuel || enableFilterByTransmission;

  let gridClasses = "sm:grid-cols-1";

  if (enableAnyFilter) {
    if (istabletScreen || isIpadMiniScreen) {
      gridClasses = "md:grid-cols-2 gap-4";
    } else if (isDesktopScreen) {
      if (errorMsgNoVehicle || availableVehicles.length == 0) {
        gridClasses = "md:grid-cols-1";
      } else if (appSettings?.showThreeVehicleCardInArow) {
        gridClasses = "grid-cols-4 gap-4";
      } else {
        gridClasses = "md:grid-cols-3";
      }
    }
  } else {
    if (isDesktopScreen) {
      if (availableVehicles.length == 0) {
        gridClasses = "grid-cols-3 gap-4";
      } else {
        gridClasses = "grid-cols-4 gap-4";
      }
      // gridClasses = "grid-cols-4 gap-4";
    } else if (istabletScreen || isIpadMiniScreen) {
      gridClasses = "md:grid-cols-2 gap-4";
    }
  }

  return (
    <>
      {!isLoadingMoreVehicles && (
        <div
          className={`font-bold text-center text-black grid ${gridClasses} gap-4 h-auto ${className}`}
        >
          {availableVehicles.length ? (
            availableVehicles.map((el: Vehicle, index) => {
              return (
                <div
                  key={index}
                  className="border-solid   border-gray-300 rounded-tl-[32px] h-auto cursor-pointer hover:border-blue-500 hover:border-3"
                  style={{
                    border: appSettings?.vehicleCardRoundEdge ? "0px" : "2px",
                    borderTopRightRadius: appSettings?.vehicleCardRoundEdge
                      ? "32px"
                      : "0px",
                    borderBottomRightRadius: appSettings?.vehicleCardRoundEdge
                      ? "32px"
                      : "12px",
                    borderBottomLeftRadius: appSettings?.vehicleCardRoundEdge
                      ? "32px"
                      : "0px",
                    background: backgroundColor,
                  }}
                >
                  {AppSettings?.showCardInPitView ? (
                    <VehicleCardPitView
                      extras={
                        el.optionals
                          ? el.optionals?.map((e) => {
                              return {
                                ...e,
                                iconUrl: e.Equipment.optionalImage || "#",
                                maxAllowed: e.Equipment.isMultipliable ? 20 : 1,
                                optionName: e.Equipment?.EquipType || "",
                                pricePerDayInUSD: (
                                  e.Charge.Amount || 0
                                ).toString(),
                                details: e.Equipment?.Description || "",
                              };
                            })
                          : []
                      }
                      discount={
                        userId != null && userId != "null" && userId != ""
                          ? 0
                          : el.Vehicle?.payNowDis || 0
                      }
                      status={el.Status}
                      modelYear=""
                      vehicleInternationalCode={
                        el.Vehicle?.groupPic?.internationalCode
                      }
                      vehicleGroup={el.Vehicle?.groupPic?.nationalCode || ""}
                      priceDetails={{
                        payNow: (
                          (el.Reference.ID > 0
                            ? el.Reference.originalAmount - el.Reference.ID
                            : el.TotalCharge?.RateTotalAmount) || 0
                        ).toString(),
                        payLater: (
                          (el.Reference.ID > 0
                            ? el.Reference.originalAmount - el.Reference.ID
                            : el.TotalCharge?.RateTotalAmount) || 0
                        ).toString(),
                      }}
                      actualPrice={el.Reference.originalAmount}
                      specifications={
                        el.Vehicle?.vehicleParameter
                          ? (function () {
                              const specificationsArray: {
                                iconUrl: string;
                                name: string;
                                value: string;
                              }[] = [];
                              const vehicleParameter =
                                el.Vehicle.vehicleParameter;
                              if (vehicleParameter.length > 0) {
                                for (
                                  let i = 0;
                                  i < vehicleParameter.length;
                                  i++
                                ) {
                                  const e = vehicleParameter[i];
                                  const specification = {
                                    iconUrl: e["fileUrl :"]
                                      ? e?.["fileUrl :"]
                                      : e?.fileUrl,
                                    name: e["name :"] ? e?.["name :"] : e?.name,
                                    value: e["position :"]
                                      ? e?.["position :"]
                                      : e?.position,
                                  };
                                  specificationsArray.push(specification);
                                }
                              }
                              return specificationsArray;
                            })()
                          : []
                      }
                      topFeature={""}
                      vehicleImages={[
                        el.Vehicle?.vehicleGroupPic || "",
                        ...(el.Vehicle?.vehicelExtraImage || []).filter((e) => {
                          return !!e;
                        }),
                      ]}
                      vehicleName={
                        appSettings?.showGroupWebDescription
                          ? el.Vehicle?.groupWebDescription ||
                            el.Vehicle?.VehMakeModel?.Name ||
                            ""
                          : el.Vehicle?.VehMakeModel?.Name || ""
                      }
                      payLater={"Pay Later"}
                      disclaimer={
                        "Le opzioni di questo veicolo da consegnare possono variare"
                      }
                      payNow={"Pay Now"}
                      selectExtra={"Choose Insurance"}
                      closeExtra={"Chiudi extra"}
                      viewAllSpecifications={"Visualizza tutte le specifiche"}
                      vehicleStatus={el.Status}
                      moveToPayment={(e) => {
                        onVehicleSelect({
                          vehicleDetails: el,
                          paymentDetails: e,
                        });
                      }}
                      textColor={textColor}
                      backgroundColor={backgroundColor}
                      backgroundTextColor={backgroundTextColor}
                      addedOnewayOOHFee={el.addedOnewayOOHFee}
                      addedOnewayFee={el.addedOnewayFee}
                      addedNoAgeFee={el.addedNoAgeFee}
                      addedYoungDriver2Fee={el.addedYoungDriver2Fee}
                      addedSeniorDriverFee={el.addedSeniorDriverFee}
                      addedYoungDriverFee={el.addedYoungDriverFee}
                      addedOOHFee={el.addedOOHFee}
                      theftExcessAmount={el.Vehicle?.theftAccessAmount}
                      DepositAmount={el.TotalCharge?.DepositAmount}
                      damageExcessAmount={el?.Vehicle?.damageAccessAmount}
                      depositAmtFromOpt={el?.depositAmtFromOpt}
                      reservationSourcePayNow={reservationSourcePayNow ?? ""}
                      reservationSourcePayLater={
                        reservationSourcePayLater ?? ""
                      }
                      isDiscountApplied={el.Reference.ID > 0}
                      additionalTotalFee={el.additionalTotalFee}
                      updateTotalCardHeightMobile={onCardHeightChangeMobile}
                    />
                  ) : (
                    <BookingVehicleInfoCard
                      extras={
                        el.optionals
                          ? el.optionals?.map((e) => {
                              return {
                                ...e,
                                iconUrl: e.Equipment.optionalImage || "#",
                                maxAllowed: e.Equipment.isMultipliable ? 20 : 1,
                                optionName: e.Equipment?.EquipType || "",
                                pricePerDayInUSD: (
                                  e.Charge.Amount || 0
                                ).toString(),
                                details: e.Equipment?.Description || "",
                              };
                            })
                          : []
                      }
                      discount={
                        userId != null && userId != "null" && userId != ""
                          ? 0
                          : el.Vehicle?.payNowDis || 0
                      }
                      status={el.Status}
                      modelYear=""
                      vehicleGroup={el.Vehicle?.groupPic?.nationalCode || ""}
                      priceDetails={{
                        payNow: (
                          el.TotalCharge?.RateTotalAmount || 0
                        ).toString(),
                        payLater: (
                          el.TotalCharge?.RateTotalAmount || 0
                        ).toString(),
                      }}
                      specifications={
                        el.Vehicle?.vehicleParameter
                          ? (function () {
                              const specificationsArray: {
                                iconUrl: string;
                                name: string;
                                value: string;
                              }[] = [];
                              const vehicleParameter =
                                el.Vehicle.vehicleParameter;
                              if (vehicleParameter.length > 0) {
                                for (
                                  let i = 0;
                                  i < vehicleParameter.length;
                                  i++
                                ) {
                                  const e = vehicleParameter[i];
                                  const specification = {
                                    iconUrl: e["fileUrl :"]
                                      ? e?.["fileUrl :"]
                                      : e?.fileUrl,
                                    name: e["name :"] ? e?.["name :"] : e?.name,
                                    value: e["position :"]
                                      ? e?.["position :"]
                                      : e?.position,
                                  };
                                  specificationsArray.push(specification);
                                }
                              }
                              return specificationsArray;
                            })()
                          : []
                      }
                      topFeature={""}
                      vehicleImages={[
                        el.Vehicle?.vehicleGroupPic || "",
                        ...(el.Vehicle?.vehicelExtraImage || []).filter((e) => {
                          return !!e;
                        }),
                      ]}
                      vehicleName={
                        appSettings?.showGroupWebDescription
                          ? el.Vehicle?.groupWebDescription ||
                            el.Vehicle?.VehMakeModel?.Name ||
                            ""
                          : el.Vehicle?.VehMakeModel?.Name || ""
                      }
                      payLater={"Pay Later"}
                      disclaimer={
                        "Le opzioni di questo veicolo da consegnare possono variare"
                      }
                      payNow={"Pay Now"}
                      selectExtra={"Choose Insurance"}
                      closeExtra={"Chiudi extra"}
                      viewAllSpecifications={"Visualizza tutte le specifiche"}
                      vehicleStatus={el.Status}
                      moveToPayment={(e) => {
                        onVehicleSelect({
                          vehicleDetails: el,
                          paymentDetails: e,
                        });
                      }}
                      textColor={textColor}
                      backgroundColor={backgroundColor}
                      backgroundTextColor={backgroundTextColor}
                      addedOnewayOOHFee={el.addedOnewayOOHFee}
                      addedOnewayFee={el.addedOnewayFee}
                      addedNoAgeFee={el.addedNoAgeFee}
                      addedYoungDriver2Fee={el.addedYoungDriver2Fee}
                      addedSeniorDriverFee={el.addedSeniorDriverFee}
                      addedYoungDriverFee={el.addedYoungDriverFee}
                      addedOOHFee={el.addedOOHFee}
                      theftExcessAmount={el.Vehicle?.theftAccessAmount}
                      damageExcessAmount={el?.Vehicle?.damageAccessAmount}
                      depositAmtFromOpt={el?.depositAmtFromOpt}
                      reservationSourcePayNow={reservationSourcePayNow ?? ""}
                      reservationSourcePayLater={
                        reservationSourcePayLater ?? ""
                      }
                    />
                  )}
                </div>
              );
            })
          ) : (
            <>
              {!istabletScreen && <div></div>}
              <div
                className="text-500"
                style={{
                  marginLeft: istabletScreen ? "55%" : "0",
                  marginTop: "4rem",
                  color:
                    process.env.REACT_APP_COMPANY_CODE === "felirent"
                      ? "white"
                      : "red",
                }}
              >
                {noVehicleLabel} <br />
                {errorMsgNoVehicle !=
                "Not accepting connections. Please try again later." ? (
                  <span>{errorMsgNoVehicle}</span>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </div>
      )}
      {isLoadingMoreVehicles && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
