import "./App.css";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { BasePageLayout } from "./components/Layouts/BasePage";
import { Router } from "./router/router";
import { useDispatch } from "react-redux";
import { PageConfigActions } from "./store/PageConfigurations";
import React, { useEffect, useState } from "react";
import BookingApi from "./API/Apis/BookingApi";
import { BOOKING_ACTIONS } from "./store/onlineBooking";
import i18n from "./config/i18n";
import theme, { formatTheme } from "./config/MuiColorThemes";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
/*function getLanguageFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("lang") || "it";
}*/
function App() {
  useEffect(() => {
    // ------- BOOK-467 2. Paste this code immediately after the opening <body> tag: -------------
    if (process.env.REACT_APP_COMPANY_CODE === "viaggiare") {
      const noscript = document.createElement("noscript");
      noscript.innerHTML =
        '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-59WX4NR" height="0" width="0" style="display:none;visibility:hidden"></iframe>';

      document.body.appendChild(noscript);
    }

    if (process.env.REACT_APP_COMPANY_CODE === "viaggiare") {
      console.log("Injecting Affilired tracking script for Viaggiare...");
      const affiliredScript = document.createElement("script");
      affiliredScript.type = "text/javascript";
      affiliredScript.async = true;
      affiliredScript.src =
        "https://customs.affilired.com/track/?merchant=4918";
      document.body.appendChild(affiliredScript);

      // function removeAffiliredScript() {
      //   if (affiliredScript.parentNode) {
      //     affiliredScript.parentNode.removeChild(affiliredScript);
      //     console.log("Affilired tracking script removed.");
      //   }
      // }
      // removeAffiliredScript();
    }

    // --------- BOOK-467 1. Paste this code as high as possible in the <head> section of the page -----
    if (process.env.REACT_APP_COMPANY_CODE === "viaggiare") {
      console.log("Injecting Google Tag Manager for Viaggiare...");

      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-59WX4NR");
    }
  }, []);

  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(PageConfigActions.setHomePageConfig({}));
    dispatch(PageConfigActions.setHeaderConfig({}));
    dispatch(PageConfigActions.setFooterConfig({}));
    BookingApi.getAppSetting().then((e) => {
      dispatch(BOOKING_ACTIONS.setAppSetting(e));
      //for felirent font-family will be Montserrat and for every other client it is by default
      const fontFamilyStyle = e?.fontFamily;
      if (fontFamilyStyle) {
        document.documentElement.style.setProperty(
          "--font-primary",
          fontFamilyStyle
        );
      }
    });
    BookingApi.getPrivacyAndTerms().then((e) => {
      dispatch(BOOKING_ACTIONS.setRentalTerms(e.data.conditionText));
    });
    /*const language = getLanguageFromUrl();
    BookingApi.getTextResource(language).then((response) => {
      const textResource: BookingTextResource = response.data;
      dispatch(BOOKING_ACTIONS.setTextResource(textResource));
    });*/
  }, []);
  const [queryParams] = useSearchParams();
  useEffect(() => {
    const currentLanguage = queryParams.get("lang");
    if (currentLanguage && i18n.languages.includes(currentLanguage)) {
      i18n.changeLanguage(currentLanguage);
    }
  }, []);
  return (
    <ThemeProvider theme={dynamicTheme || theme}>
      <BasePageLayout>
        <div>
          <Routes>
            {Router.map((el, index) => {
              let shouldRedirect = false;
              let redirectUrl = "/";
              if (el.shouldRedirect) {
                shouldRedirect = el.shouldRedirect();
              }
              if (el.redirectUrl) {
                redirectUrl = el.redirectUrl;
              }
              return (
                <Route
                  key={index}
                  path={el.path}
                  element={
                    <ProtectedRoute
                      shouldRedirect={shouldRedirect}
                      url={redirectUrl}
                      child={el.element}
                    ></ProtectedRoute>
                  }
                >
                  {el.children &&
                    el.children.map((nested, id) => {
                      return (
                        <Route
                          key={id}
                          path={nested.path}
                          element={
                            <ProtectedRoute
                              shouldRedirect={shouldRedirect}
                              url={redirectUrl}
                              child={nested.element}
                            />
                          }
                        />
                      );
                    })}
                </Route>
              );
            })}
          </Routes>
        </div>
      </BasePageLayout>
    </ThemeProvider>
  );
}
interface ProtectedRouteProps {
  child: Element | JSX.Element;
  shouldRedirect: boolean;
  url: string;
  children?: any;
}

function ProtectedRoute({
  child,
  shouldRedirect,
  url = "/",
}: ProtectedRouteProps): JSX.Element {
  if (shouldRedirect) {
    return <Navigate to={url} />;
  }
  return <>{child}</>;
}
export default App;
