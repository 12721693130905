import React, { useEffect, useState } from "react";
import DoneTwoTone from "@mui/icons-material/DoneTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DeleteOutline } from "@material-ui/icons";
import BookingGetters from "../../../store/onlineBooking/getters";
import i18n from "../../../config/i18n";

const InsurancePreview = ({
  insurance,
  specification,
  updateShowInsurance,
  previewModal,
}) => {
  const [addedSpecification, setAddedSpecification] = useState([]);
  const { t: translate } = useTranslation();
  const lang = i18n.language;
  const textResource = BookingGetters.getTextResourceDetailsBasedOnLang();
  const data = textResource?.[lang]?.flatMap((item) => item.data || []) || [];
  useEffect(() => {
    let newSpecifications;
    if (previewModal === "selectedInsurance") {
      newSpecifications = specification.reduce((acc, e) => {
        if (e.Description === insurance.details) {
          const bookingTextEntries = Object.entries(e.bookingText)
            .filter(([key]) => key.includes(e.Description)) // Filter entries containing the description
            .map(([key, value]) => {
              const cleanedKey = key.replace(`:${e.Description}`, ""); // Remove the description from the key
              const matchingData = data.find((item) => {
                return (
                  item.key.trim().toLowerCase() === cleanedKey.toLowerCase()
                );
              });
              const matchingValueData = data.find((item) => {
                return (
                  item.key.trim().toLowerCase() ===
                  (value as string).trim().toLowerCase()
                );
              });
              const updatedValue = matchingData
                ? `${matchingData.body} : ${matchingValueData?.body || value}`
                : `${cleanedKey} : ${value}`;
              return updatedValue;
            });
          return [...acc, ...bookingTextEntries];
        }
        return acc;
      }, []);
    } else {
      newSpecifications = specification.reduce((acc, e) => {
        if (e.Description === insurance.Description) {
          const bookingTextEntries = Object.entries(e.bookingText)
            .filter(([key]) => key.includes(e.Description)) // Filter entries containing the description
            .map(([key, value]) => {
              const cleanedKey = key.replace(`:${e.Description}`, ""); // Remove the description from the key
              const matchingData = data.find((item) => {
                return (
                  item.key.trim().toLowerCase() === cleanedKey.toLowerCase()
                );
              });
              const matchingValueData = data.find((item) => {
                return (
                  item.key.trim().toLowerCase() ===
                  (value as string).trim().toLowerCase()
                );
              });
              const updatedValue = matchingData
                ? `${matchingData.body} : ${matchingValueData?.body || value}`
                : `${cleanedKey} : ${value}`;

              return updatedValue;
            });
          return [...acc, ...bookingTextEntries];
        }
        return acc;
      }, []);
    }
    setAddedSpecification(newSpecifications);
  }, [previewModal, specification, insurance, lang]);

  return (
    <div className="relative flex flex-wrap text-black animate__animated animate__zoomInRight p-8 shadow-lg rounded-lg bg-white">
      {previewModal === "selectedInsurance" && (
        <div className="absolute top-2 right-2">
          <IconButton>
            <DeleteOutline
              onClick={updateShowInsurance}
              className="text-red-500"
            />
          </IconButton>
        </div>
      )}
      {previewModal !== "selectedInsurance" && (
        <div style={{ width: "-webkit-fill-available" }}>
          <h3 className="text-xl mb-2">{translate("your.plan.includes")} </h3>
        </div>
      )}
      {previewModal === "selectedInsurance" ? (
        <div style={{ width: "-webkit-fill-available" }}>
          <h1 className="text-xl font-bold mb-2">{insurance.details}</h1>
        </div>
      ) : (
        <div style={{ width: "-webkit-fill-available" }}>
          <h1 className="text-xl font-bold mb-2">{insurance.Description}</h1>
        </div>
      )}
      <div>
        <ul className="list-disc pl-5">
          {addedSpecification.map((spec, index) => (
            <li key={index} className="flex items-center">
              <DoneTwoTone className="text-green-800 font-bold mr-2" />
              {spec}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default InsurancePreview;
